import React from "react";
import "../App.css";
import image1 from '../assets/percentagepro_hero.png';
import image2 from '../assets/mobile.png';

function Home() {
  return (
    <div class="pp_container">
      <div >
        <img class="home_image1" src={image1} alt="frustrated woman doing math" />
          <img class="home_image2" src={image2} alt="frustrated woman doing math" />
      </div>
      <div id="text-container">
        <p>
          This tool was created to help solve percentage problems
          where formulas are too much to try to resolve in your head.
          Whether you want to solve a quick addition, or view a table of
          results, you can quickly solve these probelms using the Percentage Pro
          calculator.
        </p>
   
      </div>
    </div>
  );
}

export default Home;
