import "../App.css";
import "../assets/nav.css";

import React, { Component } from "react";
import { Link } from "react-router-dom";
class Navigation extends Component {
  render() {
    return (
      <div class="header-split">
        <div class="nav-item">
          <ul class="links">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/simplemath">Simple Math</Link>
            </li>
            <li>
              <Link to="/percentagedifference">Percentage Difference</Link>
            </li>
            <li>
              <Link to="/percentageofnumber">Percentage of a Number</Link>
            </li>
            <li>
              <Link to="/tips">Calculate Tips</Link>
            </li>
            <li>
              <Link to="/interestrate">Interest Rate</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default Navigation;
