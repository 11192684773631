import React from "react";
import { useState } from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";


function SimpleMath() {

  let [num, setNum] = useState(0);
  let [perc, setPerc] = useState(0);
  let sm_answer = 0;

  const updateNum = (event) => {
    setNum(event.target.valueAsNumber);
  };
  const updatePerc = (event) => {
    setPerc(event.target.valueAsNumber);
  };
  
  const handleClick = (e) => {
    // Validate Fields
    var numberRGX = /^[0(-9]+\.?[0-9]*$/ // Decimal Numbers For decimal numbers with one decimal point
    var numberResult = numberRGX.test(num);
    var percResult = numberRGX.test(perc);
    
    if(numberResult === false || num === null){
      alert('Fields cannot be blank, and must be a number.')
    }
    if(percResult === false || perc === null){
      alert('Percentage cannot be blank, and must be a number.')
    }
    //Fields are Valid
    if (numberResult === true && percResult === true){
      var operator = document.getElementById("math").value;
      console.log(num, operator, perc)
      if (operator === "plus") {
        sm_answer = num + (num * (perc / 100));
      } else if (operator === "minus") {
        sm_answer = num - (num * (perc / 100));
      }

      const sm_resultsElement = document.getElementById("sm_results");
      const sm_results = createRoot(sm_resultsElement);
    
      sm_results.render(
        <StrictMode>
          <p>{sm_answer}</p>
        </StrictMode>
      );
    }
  };
  const handleClear = (e) => {
    document.getElementById("sm_number").value = "";
    document.getElementById("sm_percentage").value = "";
    document.getElementById("sm_results").innerHTML = "<p>...</p>";
    setNum = 0;
    setPerc = 0;
  };
  return (
    <>
      <div class="pp_container">
        <p>Add, or Subtract a percentage to/from a number.</p>
        <input
          id="sm_number"
          type="number"
          class="pp_input"
          name="number"
          onChange={updateNum}
          required
        />
        <select name="math" id="math" class="pp_input">
          <option value="plus" select = "true">+</option>
          <option value="minus">-</option>
        </select>
        
        <input
          name="per"
          id="sm_percentage"
          type="number"
          class="pp_input"
          onChange={updatePerc}
          required
        />
        %<br></br>
        <button name="solve" onClick={handleClick}>
          Solve
        </button> 
        <button name="clear" onClick={handleClear}>
          Clear
        </button>
        <br />
        <div class="pp_answer" id="sm_results">
          ...
        </div>
      </div>
    </>
  );
}
export default SimpleMath;
