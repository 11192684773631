import React from "react";
import { useState } from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

function Tips() {
  // us po to prefix Number and Percentage variables
  const [total, setTotal] = useState(0);
  // const [split, setSplit] = useState(0);
  const [tip1, setTip1] = useState(8);
  const [tip2, setTip2] = useState(10);
  const [tip3, setTip3] = useState(15);
  const [tip4, setTip4] = useState(20);

  // Initialize default tip values.
  // setTip1(8.0);
  // setTip2(10.0);
  // setTip3(15.0);
  // setTip4(20.0);

  const updateTotal = (event) => {
    setTotal(event.target.valueAsNumber);
  };
  // const updateSplit = (event) => {
  //   setSplit(event.target.valueAsNumber);
  // };
  const updateTip1 = (event) => {
    setTip1(event.target.valueAsNumber);
  };
  const updateTip2 = (event) => {
    setTip2(event.target.valueAsNumber);
  };
  const updateTip3 = (event) => {
    setTip3(event.target.valueAsNumber);
  };
  const updateTip4 = (event) => {
    setTip4(event.target.valueAsNumber);
  };

  const handleClick = (e) => {
    const headerArray = [
      ["Tip%", "Tip Amount", "Total Cost", "Tip to Round", "Total Rounded"],
    ];
    const bodyArray = [];

    // Array Fill 1
    let tipPerc1 = tip1 + "%";
    let tipTotal1 = total * (tip1 / 100);
    let totalCost1 = total + total * (tip1 / 100);
    let totalCostRnd1 = Number(total + tipTotal1).toFixed(0);
    let tipToRound1 = totalCostRnd1 - total;

    console.log("Total Cost Rounded: ", totalCostRnd1);

    //Push the inital values to the array.
    bodyArray.push([
      tipPerc1,
      formatter.format(tipTotal1),
      formatter.format(totalCost1),
      formatter.format(tipToRound1),
      formatter.format(totalCostRnd1),
    ]);

    // Array Fill 2
    let tipPerc2 = tip2 + "%";
    let tipTotal2 = total * (tip2 / 100);
    let totalCost2 = total + total * (tip2 / 100);
    let totalCostRnd2 = Number(total + tipTotal2).toFixed(0);
    let tipToRound2 = totalCostRnd2 - total;

    console.log("Total Cost Rounded: ", totalCostRnd2);

    //Push the inital values to the array.
    bodyArray.push([
      tipPerc2,
      formatter.format(tipTotal2),
      formatter.format(totalCost2),
      formatter.format(tipToRound2),
      formatter.format(totalCostRnd2),
    ]);

    // Array Fill 3
    let tipPerc3 = tip3 + "%";
    let tipTotal3 = total * (tip3 / 100);
    let totalCost3 = total + total * (tip3 / 100);
    let totalCostRnd3 = Number(total + tipTotal3).toFixed(0);
    let tipToRound3 = totalCostRnd3 - total;

    console.log("Total Cost Rounded: ", totalCostRnd3);

    //Push the inital values to the array.
    bodyArray.push([
      tipPerc3,
      formatter.format(tipTotal3),
      formatter.format(totalCost3),
      formatter.format(tipToRound3),
      formatter.format(totalCostRnd3),
    ]);
    // Array Fill 4
    let tipPerc4 = tip4 + "%";
    let tipTotal4 = total * (tip4 / 100);
    let totalCost4 = total + total * (tip4 / 100);
    let totalCostRnd4 = Number(total + tipTotal4).toFixed(0);
    let tipToRound4 = totalCostRnd4 - total;

    console.log("Total Cost Rounded: ", totalCostRnd4);

    //Push the inital values to the array.
    bodyArray.push([
      tipPerc4,
      formatter.format(tipTotal4),
      formatter.format(totalCost4),
      formatter.format(tipToRound4),
      formatter.format(totalCostRnd4),
    ]);

    const tp_resultsElement = document.getElementById("tp_results");
    const tp_results = createRoot(tp_resultsElement);
    tp_results.render(
      <StrictMode>
        <table class="boostrap4">
          <thead>
            <tr>
              {headerArray[0].map((head, index) => {
                return <th>{head}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {bodyArray.slice(0, bodyArray.length).map((item, index) => {
              return (
                <tr>
                  <td>{item[0]}</td>
                  <td>{item[1]}</td>
                  <td>{item[2]}</td>
                  <td>{item[3]}</td>
                  <td>{item[4]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </StrictMode>
    );
  };

  const handleClear = (e) => {
    document.getElementById("tp_total").defaultValue = 0.0;
    document.getElementById("tp_split").value = 1;
    document.getElementById("tip1").defaultValue = 8.0;
    document.getElementById("tip2").defaultValue = 12.0;
    document.getElementById("tip3").defaultValue = 15.0;
    document.getElementById("tip4").valuevalue = 20.0;
    document.getElementById("tp_results").innerHTML = "<p>...</p>";
    // eslint-disable-next-line no-const-assign
    setTotal = 0;
    // eslint-disable-next-line no-const-assign
    // setSplit = 0;
    // eslint-disable-next-line no-const-assign
    setTip1 = 8.0;
    // eslint-disable-next-line no-const-assign
    setTip2 = 10.0;
    // eslint-disable-next-line no-const-assign
    setTip3 = 15.0;
    // eslint-disable-next-line no-const-assign
    setTip4 = 20.0;
  };
  return (
    <>
      <div class="pp_container">
        <p>Total, Percentage, Tip Amount.</p>
        <label>Enter Total:</label>
        <br />
        <input
          name="tp_total"
          id="tp_total"
          class="pp_input"
          type="number"
          step="0.01"
          onChange={updateTotal}
          defaultValue="0.00"
        />
        <br />
        {/* Split the bill will be added at a later time */}
        {/* <label>Split The Bill:</label>
        <br />
        <input
          name="tp_split"
          id="tp_split"
          class="pp_input"
          type="number"
          step="0.01"
          onChange={updateSplit}
          defaultValue="1"
        /> */}
        <label>Tip Amounts:</label>
        <br />
        <input
          name="tip1"
          id="tip1"
          class="pp_input"
          type="number"
          step="0.01"
          onChange={updateTip1}
          defaultValue="8.0"
        />
        <input
          name="tip2"
          id="tip2"
          class="pp_input"
          type="number"
          step="0.01"
          onChange={updateTip2}
          defaultValue="10.0"
        />
        <input
          name="tip3"
          id="tip3"
          class="pp_input"
          type="number"
          step="0.01"
          onChange={updateTip3}
          defaultValue="15.0"
        />
        <input
          name="tip4"
          id="tip4"
          class="pp_input"
          type="number"
          step="0.01"
          onChange={updateTip4}
          defaultValue="20.0"
        />
        <br />
        <button name="solve" onClick={handleClick}>
          Solve
        </button>
        <button name="clear" onClick={handleClear}>
          Clear
        </button>
        <br />
        <div class="pp_answer" id="tp_results">
          <p>...</p>
        </div>
      </div>
    </>
  );
}
export default Tips;
