import React from "react";
import "../App.css";
import { useState } from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";


// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
let paySequence = 0;
function InterestRate() {
  // us po to prefix Number and Percentage variables
  
  let [payment, setPayment] = useState(0);
  const [intRate, setIntRate] = useState(0);
  const [intBalance, setBalance] = useState(0);
  const updateBalance = (event) => {
    setBalance(event.target.valueAsNumber);
  };
  const updatePayment = (event) => {
    setPayment(event.target.valueAsNumber);
    console.log(payment);
  };
  const updateIntRate = (event) => {
    setIntRate(event.target.valueAsNumber);
  };

  const handleTipClick = (e) => {
    const headerArray = [
      [
        "Payment",
        "Balance",
        "Interest",
        "Payment",
        "Cumulative Interest",
        "Remaining Balance"
      ],
    ];
    var bodyArray = [];
    //Add to the array.
    //The initial Balance, (Interest = Interest plus, Payment amounts, and 0 as cumulative interest.
    //And the New Balance = Balance + 
    paySequence += 1; //The amount of the Balance added plus interest
    let totalBalance = intBalance; //The amount of the Balnce added plus interest.
    let appliedInt = intRate / 100 / 12; //Amount of interest applied to Balance as a percentage
    let totalInterest = totalBalance * appliedInt; //Initialize as currCummInterest
    let totalRemaining = totalBalance + totalInterest - payment; //Will update for each payment
    let cummInterest = totalInterest; //Add the current interest to the cummInterest variable..

    //Push the inital values to the array.
    bodyArray.push([
      paySequence,
      formatter.format(totalBalance),
      formatter.format(totalInterest),
      formatter.format(payment),
      formatter.format(cummInterest),
      formatter.format(totalRemaining),
    ]);
    let currentBalance = totalRemaining;
    do {
      totalInterest = currentBalance * appliedInt;
      console.log(currentBalance);
      cummInterest += totalInterest;
      if (currentBalance + totalInterest > payment) {
        totalRemaining = currentBalance + totalInterest - payment;
      } else {
        payment = currentBalance + totalInterest;
        totalRemaining = currentBalance + totalInterest - payment;
      }
      bodyArray.push([
        paySequence += 1,
        formatter.format(currentBalance),
        formatter.format(totalInterest),
        formatter.format(payment),
        formatter.format(cummInterest),
        formatter.format(totalRemaining),
      ]);
      currentBalance= totalRemaining;
    } while (totalRemaining > 0);
    const ir_resultsElement = document.getElementById("ir_results");
    const ir_results = createRoot(ir_resultsElement);
    ir_results.render(
      <StrictMode>
        <table class="boostrap4">
          <thead>
            <tr>
              {headerArray[0].map((head, index) => {
                return <th>{head}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {bodyArray.slice(0, bodyArray.length).map((item, index) => {
              return (
                <tr>
                  <td>{item[0]}</td>
                  <td>{item[1]}</td>
                  <td>{item[2]}</td>
                  <td>{item[3]}</td>
                  <td>{item[4]}</td>
                  <td>{item[5]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </StrictMode>
    );
    // console.log(dataTable);
  };

  const handleClear = (e) => {
    paySequence = 0;
    document.getElementById("ir_balance").value = "Balance";
    document.getElementById("ir_payment").value = "Payment Amount";
    document.getElementById("ir_interestrate").value = "Interest Rate.";
    document.getElementById("ir_results").innerHTML = "...";
    setPayment = 0;
    // eslint-disable-next-line no-const-assign
    setIntRate = 0;
    
  };
  return (
    <>
      <div class="pp_container">
        <p id="pp_title">View Balance, Payment, and Interest for each month.</p>
        <label>Enter the balance:</label>
        <br />
        <input
          name="balance"
          id="ir_balance"
          class="pp_input"
          type="number"
          step="0.01"
          onChange={updateBalance}
          defaultValue="0.00"
          re
        />
        <br />
        <label>Enter the Monthly Payment:</label>
        <br />
        <input
          name="payments"
          id="ir_payment"
          class="pp_input"
          type="number"
          step="0.01"
          onChange={updatePayment}
          defaultValue="0.00"
        />
        <br />
        <label>Enter the Annual Percentage Rate:</label>
        <br />
        <input
          type="number"
          step="0.01"
          id="ir_interestrate"
          class="pp_input"
          name="number"
          onChange={updateIntRate}
          defaultValue="0.00"
        />
        <br />
        <button name="solve" onClick={handleTipClick}>
          Solve
        </button>
        <button name="clear" onClick={handleClear}>
          Clear
        </button>
        <br />
        <div class="pp_answer" id="ir_results">
          ...
        </div>
      </div>
    </>
  );
}
export default InterestRate;
