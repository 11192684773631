import React from 'react';
import "../App.css";
import { Link } from "react-router-dom";

function Footer() {
    

return (
    <div>
        2023 PercentagePRO      |       <Link to="/termsandconditions">Terms and Conditions</Link>
    </div>
    );
    
};
export default Footer;