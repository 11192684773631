//file: /components/Header.js
import React from "react";

function Header() {
  return (
    <div class="title">
        <title>PERCENTAGEPRO</title>
        <label>PERCENTAGE<b>PRO</b></label>
    </div>
  );
}
export default Header;
