import React from "react"
import { useState } from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

function PercentageDifference() {
    // us po to prefix NUmber and Percentage variables
    let[pdNum1, setPdNum1]= useState(0);
    let [pdNum2, setPdNum2] = useState(0);
    let pd_answer = Number;

    const updatePdNum1 =(event) => { 
        setPdNum1(event.target.valueAsNumber);
      }; 
    const updatePdNum2  =(event) => { 
        setPdNum2(event.target.valueAsNumber);
    }; 
    
    const handleClick = (e) => {
        let x = (pdNum1/pdNum2)*100;
        console.log(x)
        pd_answer = x+ "%";
        const pd_resultsElement = document.getElementById("pd_results");
        const pd_results = createRoot(pd_resultsElement);
        pd_results.render (
            <StrictMode>
            <p>{pd_answer}</p>
            </StrictMode>
        )

    }
    const handleClear = (e) => {
        document.getElementById('pd_number1').value= "Enter the Percentage";
        document.getElementById('pd_number2').value= "Enter the Number.";
        document.getElementById('pd_results').innerHTML= "<p>...</p>";
        setPdNum1 = 0;
        setPdNum2 = 0;

    }
    return(
    <>
        <div class='pp_container'>
            <p>Find the percentage difference of 2 numbers.</p>
            <input 
                name="per" 
                id="pd_number1" 
                class="pp_input" 
                type="number" 
                onChange={updatePdNum1} 
                defaultValue="Enter 1st Number"
                required 
            />is what percentage of 
            <input type ="number" 
                id="pd_number2" 
                class="pp_input" 
                name="number" 
                onChange={updatePdNum2} 
                defaultValue="Enter 2nd Number"
                required
            />
            <br></br>
            <button name='solve' onClick={handleClick}>Solve</button>
            <button name='clear' onClick={handleClear}>Clear</button><br/>
            <div class="pp_answer" id="pd_results"><p>...</p></div>
        </div>
    </>
    );
};
export default PercentageDifference;