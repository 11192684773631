import React from "react"
import { useState } from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

function PercentageOfNumber() {
    // us po to prefix NUmber and Percentage variables
    let[poNum, setPoNum]= useState(0);
    let [poPerc, setPoPerc] = useState(0);

    const updatePoNum =(event) => { 
        setPoNum(event.target.valueAsNumber);
      }; 
    const updatePoPerc =(event) => { 
        setPoPerc(event.target.valueAsNumber);
    }; 
    
    const handleClick = (e) => {
        let po_answer = (poPerc/100)*poNum;
        const po_resultsElement = document.getElementById("po_results");
        const po_results = createRoot(po_resultsElement);
        po_results.render (
            <StrictMode>
            <p>{po_answer}</p>
            </StrictMode>
        )
    }
    const handleClear = (e) => {
        document.getElementById('po_percentage').value= "Enter the Percentage";
        document.getElementById('po_number').value= "Enter the Number.";
        document.getElementById('po_results').innerHTML= "<p>...</p>";
        setPoNum = 0;
        setPoPerc = 0;

    }
    return(
    <>
        <div class='pp_container'>
            <p id="pp_title">Find the percentage of a number</p>
            <input name="per" id="po_percentage" class="pp_input" type="number" onChange={updatePoPerc} defaultValue="Enter a Percentage" required/> % of 
            <input type ="number" id="po_number" class="pp_input" name="number" onChange={updatePoNum} defaultValue="Enter a Number" required/>
            <br></br><button name='solve' onClick={handleClick}>Solve</button>
            <button name='clear' onClick={handleClear}>Clear</button><br/>
            <div id="po_results"><p>...</p></div>
        </div>
    </>
    );
};
export default PercentageOfNumber;