import "./App.css";
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";

import Header from "./pages/Header";
import Footer from "./pages/Footer";
import Navigation from "./pages/Navigation";
// import Sidebar from "./pages/Sidebar.js";
import Home from "./components/Home";
import InterestRate from "./components/InterestRate";
import SimpleMath from "./components/SimpleMath";
import PercentageOfNumber from "./components/PercentageOfNumber";
import PercentageDifference from "./components/PercentageDifference";
import Tips from "./components/Tips";
import TermsAndConditions from "./components/TermsAndConditions";

class App extends Component {
  render() {
    return (
      <div class="page-wrap">
        <header class="page-header">
          <Header />
        </header>
        <nav class="page-nav">
          <Navigation />
        </nav>

        <div class="router-items">
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/simplemath" element={<SimpleMath />}></Route>
            <Route exact path="/percentagedifference" element={<PercentageDifference />}></Route>
            <Route exact path="/percentageofnumber" element={<PercentageOfNumber />}></Route>
            <Route exact path="/tips" element={<Tips />}></Route>
            <Route exact path="/interestrate" element={<InterestRate />}></Route>
            <Route exact path="/termsandconditions" element={<TermsAndConditions />}></Route>
          </Routes>
        </div>
        <footer class="page-footer">
          <Footer />
        </footer>
      </div>
    );
  }
}
export default App;
